import React, { useState } from "react";
import JSZip from "jszip";
import { Key } from "./Key";
import {
  first62ImagePropts,
  last6ImageArray,
  tenImagesPropts,
} from "./Super68ImagePropts";
import {
  compressImage,
  sendSlackMessage,
  updateLast6Images,
} from "./utils/utility";
import { getSlackMessagePayload } from "./utils/slack";

const ImageGenerator = () => {
  const [companyName, setCompanyName] = useState("");
  const [cityNames, setCityNames] = useState("");
  const [stateName, setStateName] = useState("");
  const [prompts, setPrompts] = useState([]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  // API key (in a real app, this should be secured)
  const API_KEY = Key;

  // Format name - replace spaces with hyphens
  const formatName = (name) => {
    return name.trim().replace(/\s+/g, "-").toLowerCase();
  };

  // Helper functions for file name handling
  const getNameWithoutExtension = (fileName) => {
    return fileName.substring(0, fileName.lastIndexOf("."));
  };

  const getFileExtension = (fileName) => {
    return fileName.slice(fileName.lastIndexOf("."));
  };

  // Check if a filename is a service page
  const isServicePage = (fileName) => {
    const serviceImageName = [
      "corporate-bus-rental",
      "wedding-transportation",
      "sporting-event-transportation",
      "school-event-transportation",
      "airport-shuttles",
      "private-event-transportation",
    ];
    return serviceImageName.includes(fileName);
  };

  // Check if a filename is a misc
  const isMiscImage = (fileName) => {
    const otherThanBuses = [
      "corporate-bus-rental",
      "wedding-transportation",
      "sporting-event-transportation",
      "school-event-transportation",
      "airport-shuttles",
      "private-event-transportation",
      "charter-bus",
      "56-passenger-motorcoach",
    ];
    return otherThanBuses.includes(fileName);
  };

  const generateRemixImage = async (
    index,
    prompt,
    isRegenerateImage = false
  ) => {
    try {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });
      setError(null);

      // Create FormData instance
      const formData = new FormData();

      // Add the image_request as a string
      const imageRequest = {
        prompt: prompt.prompt,
        aspect_ratio: "ASPECT_16_9",
        image_weight: 75,
        magic_prompt_option: "OFF",
        model: "V_2_TURBO",
        style_type: "GENERAL",
      };
      formData.append("image_request", JSON.stringify(imageRequest));

      // Convert the image path to a file and append it
      // Assuming prompt.image contains the path or URL to the image
      const response = await fetch(prompt.image);
      const blob = await response.blob();
      formData.append("image_file", blob, prompt.imageName);

      const apiResponse = await fetch("https://api.ideogram.ai/remix", {
        method: "POST",
        headers: {
          "Api-Key": API_KEY,
          // Note: Don't set Content-Type header when using FormData
          // It will be set automatically with the correct boundary
        },
        body: formData,
      });

      if (!apiResponse.ok) {
        throw new Error(`HTTP error! status: ${apiResponse.status}`);
      }

      const data = await apiResponse.json();

      // Access the URL from the correct path in the response
      if (data.data && data.data[0] && data.data[0].url) {
        setGeneratedImages((prev) => {
          const newImages = [...prev];
          newImages[index] = data.data[0].url;
          return newImages;
        });

        if (isRegenerateImage) {
          // slack
          const info = `*Data:*\nCompany Name: ${companyName}\n State Name: ${stateName}\n Cities: ${cityNames}`;
          const slackData = getSlackMessagePayload(isRegenerateImage, info);
          await sendSlackMessage(slackData);
        }
      } else {
        throw new Error("No image URL in response");
      }
    } catch (err) {
      setError(`Error generating remix image: ${err.message}`);
    } finally {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  const generateImage = async (
    prompt,
    index,
    imageName,
    isRegenerateImage = false
  ) => {
    try {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = true;
        return newLoading;
      });
      setError(null);

      const response = await fetch("https://api.ideogram.ai/generate", {
        method: "POST",
        headers: {
          "Api-Key": API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_request: {
            prompt,
            aspect_ratio:
              imageName === "56-passenger-motorcoach"
                ? "ASPECT_1_1"
                : "ASPECT_16_9",
            model: "V_2_TURBO",
            magic_prompt_option: "OFF",
            style_type: "GENERAL",
          },
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Access the URL from the correct path in the response
      if (data.data && data.data[0] && data.data[0].url) {
        setGeneratedImages((prev) => {
          const newImages = [...prev];
          newImages[index] = data.data[0].url; // Changed this line to access correct URL path
          return newImages;
        });
        if (isRegenerateImage) {
          // slack
          const info = `*Data:*\nCompany Name: ${companyName}\n State Name: ${stateName}\n Cities: ${cityNames}`;
          const slackData = getSlackMessagePayload(isRegenerateImage, info);
          await sendSlackMessage(slackData);
        }
      } else {
        throw new Error("No image URL in response");
      }
    } catch (err) {
      setError(`Error generating image: ${err.message}`);
    } finally {
      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = false;
        return newLoading;
      });
    }
  };

  const replacePlaceholders = (data, replacements) => {
    return data.map((item) => ({
      ...item,
      prompt: item.prompt
        .replace(/{Company}/g, replacements.company)
        .replace(/{State Name}/g, replacements.stateName),
    }));
  };

  const generateAllImages = async () => {
    if (
      stateName.trim() === "" ||
      companyName.trim() === "" ||
      cityNames.trim() === ""
    ) {
      setError("Please enter company name, state name and city name first");
      return;
    } else {
      setError("");
    }

    const last6ImagePropts = updateLast6Images(
      tenImagesPropts,
      last6ImageArray
    );

    // Get fresh data every time
    const latestImagePropts = [...first62ImagePropts, ...last6ImagePropts];

    const replacedImagesPrompts = replacePlaceholders(latestImagePropts, {
      company: companyName,
      stateName: stateName,
    });

    setPrompts(replacedImagesPrompts);

    // Initialize arrays for new batch
    setGeneratedImages(new Array(replacedImagesPrompts.length).fill(null));
    setLoading(new Array(replacedImagesPrompts.length).fill(false));

    // Generate images in batches of 5
    for (let i = 0; i < replacedImagesPrompts.length; i += 5) {
      const batchPrompts = replacedImagesPrompts.slice(i, i + 5);
      const batchPromises = batchPrompts.map((prompt, batchIndex) => {
        // Check if remix is needed and call appropriate function
        if (prompt?.isRemixNeeded) {
          return generateRemixImage(i + batchIndex, prompt);
        } else {
          return generateImage(prompt.prompt, i + batchIndex, prompt.imageName);
        }
      });
      await Promise.all(batchPromises);
    }
    // slack
    const info = `*Data:*\nCompany Name: ${companyName}\n State Name: ${stateName}\n Cities: ${cityNames}`;
    const slackData = getSlackMessagePayload(false, info);
    await sendSlackMessage(slackData);
  };

  // Updated folder structure with exact naming rules
  const folderStructure = {
    "Home Page": (fileName, siteName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(siteName);

      // Handle service pages (special cases that need site name at beginning)
      if (isServicePage(nameWithoutExt)) {
        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }

      //  HERO SECTION has different image
      if (nameWithoutExt === "charter-bus") {
        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }

      //  This is 1:1 about us image
      if (nameWithoutExt === "56-passenger-motorcoach") return;

      // 56 Images is twice on home page
      if (nameWithoutExt === "56-passenger-charter-bus") {
        return [
          `${nameWithoutExt}-${formattedSiteName}${extension}`,
          `${formattedSiteName}-${nameWithoutExt}${extension}`,
        ];
      }

      // Handle other files by adding site name at end
      return `${nameWithoutExt}-${formattedSiteName}${extension}`;
    },
    "Buses Page": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);

      // Handle misc image
      if (isMiscImage(nameWithoutExt)) {
        return;
      }

      // Keep original filename
      return fileName;
    },
    "Services Page": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(companyName);

      // Only 6 Images will be added here
      if (isServicePage(nameWithoutExt)) {
        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }
    },
    "Individual Buses Page": (fileName, siteName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(siteName);

      // Handle misc image
      if (isMiscImage(nameWithoutExt)) {
        return;
      }

      // Add site name at the end for all bus-related images
      return `${formattedSiteName}-${nameWithoutExt}${extension}`;
    },

    "Individual Service Page": (fileName, siteName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(siteName);

      // Only 6 Images will be added here
      if (isServicePage(nameWithoutExt)) {
        // 6 Images of service have different naming convention than HOME page
        if (nameWithoutExt === "corporate-bus-rental")
          return `${formattedSiteName}-corporate-bus-rental${extension}`;
        if (nameWithoutExt === "wedding-transportation")
          return `${formattedSiteName}-wedding-charter-bus-rental${extension}`;
        if (nameWithoutExt === "sporting-event-transportation")
          return `${formattedSiteName}-sports-charter-bus-rental${extension}`;
        if (nameWithoutExt === "school-event-transportation")
          return `${formattedSiteName}-school-trip-bus-rental${extension}`;
        if (nameWithoutExt === "airport-shuttles")
          return `${formattedSiteName}-airport-shuttle-bus${extension}`;
        if (nameWithoutExt === "private-event-transportation")
          return `${formattedSiteName}-private-event-bus-rental${extension}`;

        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }
      // Add one extra image

      // corporate
      if (nameWithoutExt === "35-passenger-minibus") {
        return `${formattedSiteName}-employee-shuttle${extension}`;
      }

      // Private events
      if (nameWithoutExt === "30-passenger-minibus") {
        return `${formattedSiteName}-private-bus-rental${extension}`;
      }

      // School
      if (nameWithoutExt === "school-bus-rental") {
        return `${formattedSiteName}-school-event-bus-rental${extension}`;
      }

      // Sports
      if (nameWithoutExt === "56-passenger-charter-bus") {
        return `${formattedSiteName}-sports-bus-rental${extension}`;
      }

      // Wedding
      if (nameWithoutExt === "55-passenger-charter-bus") {
        return `${formattedSiteName}-wedding-bus-rentals${extension}`;
      }

      // Airport
      if (nameWithoutExt === "28-passenger-minibus") {
        return `${formattedSiteName}-airport-bus-rental${extension}`;
      }
    },

    "Individual City Page": (fileName, siteName, cityName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedCityName = formatName(cityName);

      // Only 6 Images will be added here
      // Only 6 Images will be added here, SKIP CORPORATE TRANSPORTATION DUE TO DIFFERENT NAME
      if (
        isServicePage(nameWithoutExt) &&
        nameWithoutExt !== "corporate-bus-rental"
      ) {
        return `${formattedCityName}-${nameWithoutExt}${extension}`;
      }

      if (nameWithoutExt === "corporate-bus-rental")
        return `${formattedCityName}-corporate-transportation${extension}`;

      //  This is 1:1 about us image or hero image
      if (
        nameWithoutExt === "56-passenger-motorcoach" ||
        nameWithoutExt === "charter-bus"
      )
        return;

      // 56 Images is twice on city page
      if (nameWithoutExt === "28-passenger-minibus") {
        return [
          `${nameWithoutExt}-${formattedCityName}${extension}`,
          `${formattedCityName}-${nameWithoutExt}${extension}`,
        ];
      }

      // 28 Images is twice on city page
      if (nameWithoutExt === "56-passenger-charter-bus") {
        return [
          `${nameWithoutExt}-${formattedCityName}${extension}`,
          `${formattedCityName}-${nameWithoutExt}${extension}`,
        ];
      }

      // Handle other files by adding site name at end
      return `${nameWithoutExt}-${formattedCityName}${extension}`;
    },

    "About Us": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);

      // 1:1 aspect ratio
      if (nameWithoutExt === "56-passenger-motorcoach") {
        return `${nameWithoutExt}${extension}`;
      }

      // Random Image 1
      if (nameWithoutExt === "28-passenger-minibus") {
        return `${nameWithoutExt}-exterior${extension}`;
      }

      // Random Image 2
      if (nameWithoutExt === "56-passenger-charter-bus") {
        return `${nameWithoutExt}-exterior${extension}`;
      }

      // Random Image 3
      if (nameWithoutExt === "56-passenger-charter-bus-interior") {
        return `${nameWithoutExt}-view${extension}`;
      }
    },
    "Price Page": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);

      // Random Image 2
      if (nameWithoutExt === "56-passenger-charter-bus") {
        return `${nameWithoutExt}${extension}`;
      }

      // Random Image 1
      if (nameWithoutExt === "28-passenger-minibus") {
        return `${nameWithoutExt}${extension}`;
      }
    },
  };

  const downloadImage = async (url, prompt, imageName) => {
    // Specify Dimension
    let quality = 0.93;
    let imageWidth = 590;
    let imageHeight = 331;

    try {
      // If image aspect ratio is 1:1 update the compression
      if (imageName === "56-passenger-motorcoach") {
        imageWidth = 461;
        imageHeight = 461;
      }

      const blob = await compressImage(url, imageWidth, imageHeight, quality);
      const fileName = `${imageName}.jpeg`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      setError(`Error downloading image: ${err.message}`);
    }
  };

  const downloadAllAsZip = async () => {
    try {
      setIsDownloading(true);
      const zip = new JSZip();
      const cities = cityNames
        .split(",")
        .map((city) => city.trim())
        .filter((city) => city);

      // Process each folder structure
      for (const [folderName, nameGenerator] of Object.entries(
        folderStructure
      )) {
        if (folderName !== "Individual City Page") {
          const folder = zip.folder(folderName);

          if (folderName === "Home Page") {
            folder.folder("Destination and Neighbourhood");
          }

          // Process each generated image
          for (let i = 0; i < generatedImages.length; i++) {
            const url = generatedImages[i];
            if (!url) continue;

            const imageName = prompts[i].imageName;
            const fileName = `${imageName}.jpeg`;

            // Determine image dimensions
            let quality = 0.93;
            let imageWidth = 590;
            let imageHeight = 331;

            if (imageName === "56-passenger-motorcoach") {
              imageWidth = 461;
              imageHeight = 461;
            }

            const blob = await compressImage(
              url,
              imageWidth,
              imageHeight,
              quality
            );
            const result = nameGenerator(fileName, companyName);

            if (Array.isArray(result)) {
              result.forEach(async (name) => {
                if (name) folder.file(name, blob);
              });
            } else if (result) {
              folder.file(result, blob);
            }
          }
        }
      }

      // Process city-specific folders
      if (cities.length > 0) {
        const citiesFolder = zip.folder("Individual City Page");

        for (const city of cities) {
          const cityFolder = citiesFolder.folder(formatName(city));
          cityFolder.folder("Destination and Neighbourhood");

          for (let i = 0; i < generatedImages.length; i++) {
            const url = generatedImages[i];
            if (!url) continue;

            const imageName = prompts[i].imageName;
            const fileName = `${imageName}.jpeg`;

            let quality = 0.93;
            let imageWidth = 590;
            let imageHeight = 331;

            if (imageName === "56-passenger-motorcoach") {
              imageWidth = 461;
              imageHeight = 461;
            }

            const blob = await compressImage(
              url,
              imageWidth,
              imageHeight,
              quality
            );
            const result = folderStructure["Individual City Page"](
              fileName,
              companyName,
              city
            );

            if (Array.isArray(result)) {
              result.forEach(async (name) => {
                if (name) cityFolder.file(name, blob);
              });
            } else if (result) {
              cityFolder.file(result, blob);
            }
          }
        }
      }

      // Process each folder structure
      for (const [folderName, nameGenerator] of Object.entries(
        folderStructure
      )) {
        if (folderName !== "Individual City Page") {
          const folder = zip.folder(folderName);

          if (folderName === "Home Page") {
            folder.folder("Destination and Neighbourhood");
          }

          // Process each generated image
          for (let i = 0; i < generatedImages.length; i++) {
            const url = generatedImages[i];
            if (!url) continue;

            const imageName = prompts[i].imageName;
            const fileName = `${imageName}.jpeg`;

            // Determine image dimensions
            let quality = 0.93;
            let imageWidth = 590;
            let imageHeight = 331;

            if (imageName === "56-passenger-motorcoach") {
              imageWidth = 461;
              imageHeight = 461;
            }

            const blob = await compressImage(
              url,
              imageWidth,
              imageHeight,
              quality
            );
            const result = nameGenerator(fileName, companyName);

            if (Array.isArray(result)) {
              result.forEach(async (name) => {
                if (name) folder.file(name, blob);
              });
            } else if (result) {
              folder.file(result, blob);
            }
          }
        }
      }

      // Process city-specific folders
      if (cities.length > 0) {
        const citiesFolder = zip.folder("Individual City Page");

        for (const city of cities) {
          const cityFolder = citiesFolder.folder(formatName(city));
          cityFolder.folder("Destination and Neighbourhood");

          for (let i = 0; i < generatedImages.length; i++) {
            const url = generatedImages[i];
            if (!url) continue;

            const imageName = prompts[i].imageName;
            const fileName = `${imageName}.jpeg`;

            let quality = 0.93;
            let imageWidth = 590;
            let imageHeight = 331;

            if (imageName === "56-passenger-motorcoach") {
              imageWidth = 461;
              imageHeight = 461;
            }

            const blob = await compressImage(
              url,
              imageWidth,
              imageHeight,
              quality
            );
            const result = folderStructure["Individual City Page"](
              fileName,
              companyName,
              city
            );

            if (Array.isArray(result)) {
              result.forEach(async (name) => {
                if (name) cityFolder.file(name, blob);
              });
            } else if (result) {
              cityFolder.file(result, blob);
            }
          }
        }
      }

      const content = await zip.generateAsync({ type: "blob" });
      const formattedSiteName = formatName(companyName);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = `${formattedSiteName}-images.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      setError(`Error creating zip file: ${err.message}`);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Full Pack Image Generator</h1>

      {error && <div className="error-message">{error}</div>}

      <div className="input-section">
        <div className="d-flex">
          <input
            type="text"
            placeholder="Enter company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="normal-input mr-2"
          />
          <input
            type="text"
            placeholder="Enter state name"
            value={stateName}
            onChange={(e) => setStateName(e.target.value)}
            className="normal-input mr-2 ml-2"
          />
          <input
            type="text"
            placeholder="Enter city names (comma separated)"
            value={cityNames}
            onChange={(e) => setCityNames(e.target.value)}
            className="normal-input ml-2"
          />
        </div>
        <div className="button-group justify-content-center">
          <button
            onClick={generateAllImages}
            disabled={loading.some(Boolean)} //prompts.length === 0 ||
            className="button"
          >
            Generate All Images
          </button>
        </div>
      </div>

      <div className="prompts-container">
        {prompts.map((prompt, index) => (
          <div key={index} className="prompt-card">
            <div className="prompt-text">
              <strong>Prompt {index + 1}:</strong> {prompt.imageName}
            </div>

            {generatedImages[index] && (
              <div className="image-container">
                <img
                  src={generatedImages[index]}
                  alt={prompt.imageName}
                  className="generated-image"
                />
                <div className="button-group">
                  <button
                    onClick={() => {
                      if (prompt?.isRemixNeeded) {
                        generateRemixImage(index, prompt, true); // (index, prompt, isRegenerateImage)
                      } else {
                        generateImage(
                          prompt.prompt,
                          index,
                          prompt.imageName,
                          true
                        ); // (prompt, index, imageName, isRegenerateImage)
                      }
                    }}
                    disabled={loading[index]}
                    className="button"
                  >
                    {loading[index] ? "Generating..." : "Regenerate"}
                  </button>
                  <button
                    onClick={() =>
                      downloadImage(
                        generatedImages[index],
                        prompt.prompt,
                        prompt.imageName
                      )
                    }
                    className="button button-outline"
                  >
                    Download
                  </button>
                </div>
              </div>
            )}

            {loading[index] && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Generating image...</p>
              </div>
            )}
          </div>
        ))}
      </div>

      {generatedImages.some(Boolean) && (
        <div className="actions-container">
          <button
            onClick={downloadAllAsZip}
            className="button button-outline"
            disabled={isDownloading}
          >
            {isDownloading ? "Downloding...." : "Download All"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGenerator;
