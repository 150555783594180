import React, { useState, useEffect } from 'react';
import { GoogleGenerativeAI } from '@google/generative-ai';
import { systemPrompt } from './systemInstruction';

const STORAGE_KEY = 'gemini_api_key';

const ContentGenerator = () => {
  const [apiKey, setApiKey] = useState(() => {
    const savedKey = localStorage.getItem(STORAGE_KEY);
    return savedKey || '';
  });
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [generatedContent, setGeneratedContent] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(null);

  const [showSettings, setShowSettings] = useState(false);
    const [settings, setSettings] = useState({
    temperature: 1.15,
    topP: 0.95,
    topK: 4000,
    model: "gemini-2.0-flash-exp",
    systemPrompt: systemPrompt
    });

  // Add this utility function for delay
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    if (apiKey.trim()) {
      localStorage.setItem(STORAGE_KEY, apiKey);
    }
  }, [apiKey]);

  const handleAddPrompt = () => {
    if (!currentPrompt.trim()) {
      setError('Please enter a prompt before adding');
      return;
    }

    if (!apiKey.trim()) {
      setError('Please enter Google API key first');
      return;
    }

    const promptId = Date.now().toString();
    setPrompts(prev => [...prev, { id: promptId, text: currentPrompt.trim() }]);
    setCurrentPrompt('');
    setError(null);
  };

  const handleDeletePrompt = (promptId) => {
    setPrompts(prev => prev.filter(p => p.id !== promptId));
    setGeneratedContent(prev => {
      const newContent = { ...prev };
      delete newContent[promptId];
      return newContent;
    });
  };

  const handleCopyContent = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(null), 2000);
    } catch (err) {
      setCopySuccess('Failed to copy');
      setTimeout(() => setCopySuccess(null), 2000);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      handleAddPrompt();
    }
  };

  const clearApiKey = () => {
    localStorage.removeItem(STORAGE_KEY);
    setApiKey('');
  };

  const generateContent = async (promptId, promptText) => {
    try {
      setLoading(prev => ({ ...prev, [promptId]: true }));
      setError(null);
  
      const genAI = new GoogleGenerativeAI(apiKey);
      const model = genAI.getGenerativeModel({
        model: settings.model,
        systemInstruction: settings.systemPrompt,
      });
  
      const generationConfig = {
        temperature: settings.temperature,
        topP: settings.topP,
        topK: settings.topK,
        maxOutputTokens: 8192,
        responseMimeType: "text/plain",
      };
  
      const chatSession = model.startChat({
        generationConfig,
        history: [],
      });
  
      const result = await chatSession.sendMessage(promptText);
      const generatedText = result.response.text();
  
      // Use a functional update to avoid race conditions
      setGeneratedContent(prev => ({
        ...prev,
        [promptId]: generatedText
      }));
  
      return { success: true, promptId };
    } catch (err) {
      console.error('Generation error:', err);
      setError(`Error generating content for prompt: ${err.message}`);
      return { success: false, promptId, error: err };
    } finally {
      setLoading(prev => {
        const newLoading = { ...prev };
        delete newLoading[promptId];
        return newLoading;
      });
    }
  };

  const handleGenerateAll = async () => {
    try {
      // Process prompts in batches of 3
      for (let i = 0; i < prompts.length; i += 2) {
        const batchPrompts = prompts.slice(i, i + 2);
        const batchPromises = batchPrompts.map((prompt) => 
          generateContent(prompt.id, prompt.text)
        );
        await Promise.all(batchPromises);
        // Add delay between batches
        await delay(1000); // 1 second delay between batches
      }
    } catch (err) {
      console.error('Batch processing error:', err);
      setError(`Error in batch processing: ${err.message}`);
    }
  };

  // Add this component before the rest of your JSX return
  const SettingsModal = () => (
    <div className="bg-white p-6 rounded-lg w-96">
      <h2 className="text-xl font-bold mb-4">Settings</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Temperature</label>
          <input
            type="number"
            value={settings.temperature}
            onChange={e => setSettings(prev => ({...prev, temperature: parseFloat(e.target.value)}))}
            className="normal-input"
            step="0.01"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Top P</label>
          <input
            type="number"
            value={settings.topP}
            onChange={e => setSettings(prev => ({...prev, topP: parseFloat(e.target.value)}))}
            className="normal-input"
            step="0.01"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Top K</label>
          <input
            type="number"
            value={settings.topK}
            onChange={e => setSettings(prev => ({...prev, topK: parseInt(e.target.value)}))}
            className="normal-input"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Model</label>
          <input
            type="text"
            value={settings.model}
            onChange={e => setSettings(prev => ({...prev, model: e.target.value}))}
            className="normal-input"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">System Prompt</label>
          <textarea
            value={settings.systemPrompt}
            onChange={e => setSettings(prev => ({...prev, systemPrompt: e.target.value}))}
            className="bulk-input"
            rows={5}
          />
        </div>
        <div className="flex justify-end gap-2">
          <button onClick={() => setShowSettings(false)} className="button button-outline">
            Cancel
          </button>
          <button onClick={() => setShowSettings(false)} className="button">
            Save
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container">
      <h1 className="title">Content Generator</h1>

      {error && (
        <div className="error-message">{error}</div>
      )}

      <div className="input-section">
        <div className="relative">
          <input
            type="text"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Enter Google API Key"
            className="normal-input mb-4"
          />
          {apiKey && (
            <button
              onClick={clearApiKey}
              className="button button-outline"
              style={{ position: 'absolute', right: '0', top: '0' }}
            >
              Clear Key
            </button>
          )}
          <button 
          style={{ position: 'absolute', right: '100px', top: '0' }}
            onClick={() => setShowSettings(true)}
            className="button button-outline"
          >
                ⚙️ Settings
          </button>
        </div>

        <div className="relative mb-4">
          <textarea
            value={currentPrompt}
            onChange={(e) => setCurrentPrompt(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter your prompt (Ctrl + Enter to add)"
            className="bulk-input"
            rows={5}
          />
          <button 
            onClick={handleAddPrompt}
            className="button absolute bottom-4 right-4"
          >
            Add Prompt
          </button>
        </div>
      </div>

      <div className="prompts-container">
        {prompts.map(({ id, text }) => (
          <div key={id} className="prompt-card">
            <div className="prompt-text">
              <strong>Prompt: {text}</strong>
            </div>

            <div className="flex gap-2 mt-4">
              {!loading[id] && !generatedContent[id] && (
                <button
                  onClick={() => generateContent(id, text)}
                  className="button"
                >
                  Generate Content
                </button>
              )}
              <button
                onClick={() => handleDeletePrompt(id)}
                className="button del-button"
                style={{ backgroundColor: '#ef4444' }}
              >
                Delete
              </button>
            </div>

            {loading[id] && (
              <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Generating content...</p>
              </div>
            )}

            {generatedContent[id] && (
              <div className="generated-content">
                <h3 className="content-title">Generated Content:</h3>
                <div className="content-text">
                  {generatedContent[id]}
                </div>
                <div className="button-group">
                  <button
                    onClick={() => generateContent(id, text)}
                    className="button"
                  >
                    Regenerate
                  </button>
                  <button
                    onClick={() => handleCopyContent(generatedContent[id])}
                    className="button button-outline"
                  >
                    Copy
                  </button>
                  <button
                    onClick={() => handleDeletePrompt(id)}
                    className="button"
                    style={{ backgroundColor: '#ef4444' }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {prompts.length > 0 && !Object.keys(generatedContent).length && (
        <div className="actions-container">
          <button 
            onClick={handleGenerateAll}
            className="button"
            disabled={Object.keys(loading).length > 0}
          >
            {Object.keys(loading).length > 0 ? 'Generating...' : 'Generate All Content'}
          </button>
        </div>
      )}

      {copySuccess && (
        <div className="toast-message">
          {copySuccess}
        </div>
      )}
      {showSettings && <SettingsModal />}
    </div>
  );
};

export default ContentGenerator;