import React, { useState } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {REQUIRED_FILE_NAMES} from './RequriedFileNames';
import "./image-processor.css";

const ImageProcessor = () => {
  const [siteName, setSiteName] = useState("");
  const [cityNames, setCityNames] = useState("");
  const [files, setFiles] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");

  // Format name - replace spaces with hyphens
  const formatName = (name) => {
    return name.trim().replace(/\s+/g, "-").toLowerCase();
  };

  // Helper functions for file name handling
  const getNameWithoutExtension = (fileName) => {
    return fileName.substring(0, fileName.lastIndexOf("."));
  };

  const getFileExtension = (fileName) => {
    return fileName.slice(fileName.lastIndexOf("."));
  };

  // Check if a filename is a service page
  const isServicePage = (fileName) => {
    const serviceImageName = ["corporate-bus-rental", "wedding-transportation", "sporting-event-transportation", "school-event-transportation", "airport-shuttles", "private-event-transportation"]
    return(serviceImageName.includes(fileName));
  };

  // Check if a filename is a misc
  const isMiscImage = (fileName) => {
    const otherThanBuses = ["corporate-bus-rental", "wedding-transportation", "sporting-event-transportation", "school-event-transportation", 
      "airport-shuttles", "private-event-transportation", "charter-bus", "56-passenger-motorcoach"];
    return(otherThanBuses.includes(fileName));
  };

  // Updated folder structure with exact naming rules
  const folderStructure = {
    "Home Page": (fileName, siteName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(siteName);

      // Handle service pages (special cases that need site name at beginning)
      if (isServicePage(nameWithoutExt)) {
        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }

      //  HERO SECTION has different image
      if(nameWithoutExt === 'charter-bus') {
        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }

      //  This is 1:1 about us image
      if(nameWithoutExt === '56-passenger-motorcoach') return;

      // 56 Images is twice on home page
      if(nameWithoutExt === '56-passenger-charter-bus'){
        return[
          `${nameWithoutExt}-${formattedSiteName}${extension}`,
          `${formattedSiteName}-${nameWithoutExt}${extension}`,
        ]
      };

      // Handle other files by adding site name at end
      return `${nameWithoutExt}-${formattedSiteName}${extension}`;
    },
    "Buses Page": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      
      // Handle misc image 
      if (isMiscImage(nameWithoutExt)) {
        return;
      }
      
      // Keep original filename
      return fileName;
    },
    "Services Page": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(siteName);

      // Only 6 Images will be added here
      if (isServicePage(nameWithoutExt)) {
        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }
    },
    "Individual Buses Page": (fileName, siteName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(siteName);

      // Handle misc image 
      if (isMiscImage(nameWithoutExt)) {
        return;
      }

      // Add site name at the end for all bus-related images
      return `${formattedSiteName}-${nameWithoutExt}${extension}`;
    },

    "Individual Service Page": (fileName, siteName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedSiteName = formatName(siteName);

      // Only 6 Images will be added here
      if (isServicePage(nameWithoutExt)) {

        // 6 Images of service have different naming convention than HOME page
        if(nameWithoutExt === "corporate-bus-rental") return `${formattedSiteName}-corporate-bus-rental${extension}`;
        if(nameWithoutExt === "wedding-transportation") return `${formattedSiteName}-wedding-charter-bus-rental${extension}`;
        if(nameWithoutExt === "sporting-event-transportation") return `${formattedSiteName}-sports-charter-bus-rental${extension}`;
        if(nameWithoutExt === "school-event-transportation") return `${formattedSiteName}-school-trip-bus-rental${extension}`;
        if(nameWithoutExt === "airport-shuttles") return `${formattedSiteName}-airport-shuttle-bus${extension}`;
        if(nameWithoutExt === "private-event-transportation") return `${formattedSiteName}-private-event-bus-rental${extension}`;

        return `${formattedSiteName}-${nameWithoutExt}${extension}`;
      }
      // Add one extra image

      // corporate
        if(nameWithoutExt === '35-passenger-minibus'){
        return `${formattedSiteName}-employee-shuttle${extension}`
      };

      // Private events
      if(nameWithoutExt === '30-passenger-minibus'){
        return `${formattedSiteName}-private-bus-rental${extension}`
      };

      // School
      if(nameWithoutExt === 'school-bus-rental'){
        return `${formattedSiteName}-school-event-bus-rental${extension}`
      };

      // Sports
      if(nameWithoutExt === '56-passenger-charter-bus'){
        return `${formattedSiteName}-sports-bus-rental${extension}`
      };

      // Wedding
      if(nameWithoutExt === '55-passenger-charter-bus'){
        return `${formattedSiteName}-wedding-bus-rentals${extension}`
      };

      // Airport
      if(nameWithoutExt === '28-passenger-minibus'){
        return `${formattedSiteName}-airport-bus-rental${extension}`
      };
    },

    "Individual City Page": (fileName, siteName, cityName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      const formattedCityName = formatName(cityName);

      // Only 6 Images will be added here
      // Only 6 Images will be added here, SKIP CORPORATE TRANSPORTATION DUE TO DIFFERENT NAME
      if (isServicePage(nameWithoutExt) && nameWithoutExt !== "corporate-bus-rental") {
        return `${formattedCityName}-${nameWithoutExt}${extension}`;
      }

      if(nameWithoutExt === "corporate-bus-rental") return `${formattedCityName}-corporate-transportation${extension}`;

      //  This is 1:1 about us image or hero image
      if(nameWithoutExt === '56-passenger-motorcoach' || nameWithoutExt === 'charter-bus') return;

      // 56 Images is twice on city page
      if(nameWithoutExt === '28-passenger-minibus'){
        return[
          `${nameWithoutExt}-${formattedCityName}${extension}`,
          `${formattedCityName}-${nameWithoutExt}${extension}`,
        ]
      };

      // 28 Images is twice on city page
      if(nameWithoutExt === '56-passenger-charter-bus'){
        return[
          `${nameWithoutExt}-${formattedCityName}${extension}`,
          `${formattedCityName}-${nameWithoutExt}${extension}`,
        ]
      };

      // Handle other files by adding site name at end
      return `${nameWithoutExt}-${formattedCityName}${extension}`;
    }, 
    
    "About Us": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);

       // 1:1 aspect ratio
      if(nameWithoutExt === '56-passenger-motorcoach'){
        return `${nameWithoutExt}${extension}`
      };

      // Random Image 1
      if(nameWithoutExt === '28-passenger-minibus'){
        return `${nameWithoutExt}-exterior${extension}`
      };

      // Random Image 2
      if(nameWithoutExt === '56-passenger-charter-bus'){
        return `${nameWithoutExt}-exterior${extension}`
      };

      // Random Image 3
      if(nameWithoutExt === '56-passenger-charter-bus-interior'){
        return `${nameWithoutExt}-view${extension}`
      };
    },
    "Price Page": (fileName) => {
      const nameWithoutExt = getNameWithoutExtension(fileName);
      const extension = getFileExtension(fileName);
      
       // Random Image 2
      if(nameWithoutExt === '56-passenger-charter-bus'){
        return `${nameWithoutExt}${extension}`
      };

      // Random Image 1
      if(nameWithoutExt === '28-passenger-minibus'){
        return `${nameWithoutExt}${extension}`
      };
    }
  };

  const validateFiles = (selectedFiles) => {
    setError("");
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    
    // Check file count
    if (selectedFiles.length !== REQUIRED_FILE_NAMES.length) {
      setError(`Please upload exactly ${REQUIRED_FILE_NAMES.length} images. You uploaded ${selectedFiles.length} images.`);
      return false;
    }
  
    // Check file types
    const invalidFiles = Array.from(selectedFiles).filter(
      (file) => !validTypes.includes(file.type)
    );
  
    if (invalidFiles.length > 0) {
      setError("Only JPEG, JPG and PNG files are supported");
      return false;
    }
  
    // Check file names
    const uploadedNames = Array.from(selectedFiles).map(file => 
      getNameWithoutExtension(file.name)
    );
  
    // Find missing required files
    const missingFiles = REQUIRED_FILE_NAMES.filter(
      name => !uploadedNames.includes(name)
    );
  
    // Find extra files that aren't in the required list
    const extraFiles = uploadedNames.filter(
      name => !REQUIRED_FILE_NAMES.includes(name)
    );
  
    if (missingFiles.length > 0 || extraFiles.length > 0) {
      let errorMessage = "File naming error:\n";
      
      if (missingFiles.length > 0) {
        errorMessage += `Missing files: ${missingFiles.join(", ")}\n`;
      }
      
      if (extraFiles.length > 0) {
        errorMessage += `Unexpected files: ${extraFiles.join(", ")}`;
      }
      
      setError(errorMessage);
      return false;
    }
  
    return true;
  };

  const processAndDownload = async () => {
    if (!siteName.trim()) {
      setError("Please enter a site name");
      return;
    }

    if (!cityNames.trim()) {
      setError("Please enter city names");
      return;
    }

    if (files.length === 0) {
      setError("Please select files to upload");
      return;
    }

    setProcessing(true);
    const zip = new JSZip();

    try {
      // Process regular folders
      for (const [folderName, nameGenerator] of Object.entries(folderStructure)) {
        if (folderName !== "Individual City Page") {
          const folder = zip.folder(folderName);

          // Add blank "Destination and Neighbourhood" folder in home-page
          if (folderName === "Home Page") {
            folder.folder("Destination and Neighbourhood");
          }

          for (const file of files) {
            const result = nameGenerator(file.name, siteName);
            const arrayBuffer = await file.arrayBuffer();
            
            if (Array.isArray(result)) {
              // If the result is an array, add multiple files
              result.forEach(fileName => {
                folder.file(fileName, arrayBuffer);
              });
            } else if (result) {
              // If it's a single filename, add one file
              folder.file(result, arrayBuffer);
            }
          }
        }
      }

      // Process city-specific folders
      const citiesFolder = zip.folder("Individual City Page");
      const cities = cityNames
        .split(",")
        .map((city) => city.trim())
        .filter((city) => city);

      for (const city of cities) {
        const cityFolder = citiesFolder.folder(formatName(city));
        // Add blank "Destination and Neighbourhood" folder in each city folder
        cityFolder.folder("Destination and Neighbourhood");

        for (const file of files) {
          const result = folderStructure["Individual City Page"](
            file.name,
            siteName,
            city
          );
          const arrayBuffer = await file.arrayBuffer();

          if (Array.isArray(result)) {
            // If the result is an array, add multiple files
            result.forEach(fileName => {
              if (fileName) { // Add this check to prevent undefined files
                cityFolder.file(fileName, arrayBuffer);
              }
            });
          } else if (result) { // Add this check to prevent undefined files
            // If it's a single filename, add one file
            cityFolder.file(result, arrayBuffer);
          }
        }
      }

      const content = await zip.generateAsync({ type: "blob" });
      const formattedSiteName = formatName(siteName);
      saveAs(content, `${formattedSiteName}-images.zip`);
    } catch (err) {
      setError("Error processing files. Please try again.");
      console.error(err);
    }

    setProcessing(false);
  };

   // Function to download reference file names
   const downloadReferenceNames = () => {
    const content = REQUIRED_FILE_NAMES.join('\n');
    const blob = new Blob([content], { type: 'text/plain' });
    saveAs(blob, 'required-image-names.txt');
  };

  return (
    <div className="image-processor-container">
      <div className="image-processor-card">
        <div className="input-group">
          <div style={{textAlign: "left", color:'red', cursor: "pointer", fontSize: "10px"}} onClick={downloadReferenceNames}>
            Download Sample Txt file
          </div>
          <br/>
          <input
            type="text"
            placeholder="Enter site name"
            value={siteName}
            onChange={(e) => setSiteName(e.target.value)}
            className="site-input"
          />
        </div>

        <div className="input-group">
          <input
            type="text"
            placeholder="Enter city names (comma separated)"
            value={cityNames}
            onChange={(e) => setCityNames(e.target.value)}
            className="site-input"
          />
        </div>

        <div className="upload-area">
          <label className="upload-label">
            <div className="upload-text">Upload all Images</div>
            <div className="upload-subtext">only jpeg, jpg and png support</div>
            <input
              type="file"
              multiple
              accept=".jpg,.jpeg,.png"
              onChange={(e) => {
                const selectedFiles = e.target.files;
                if (validateFiles(selectedFiles)) {
                  setFiles(Array.from(selectedFiles));
                }
              }}
              className="file-input"
            />
          </label>
        </div>

        {files.length > 0 && (
          <div className="file-list">
            <h3 className="file-list-header">
              Selected Files ({files.length})
            </h3>
            <div className="file-list-content">
              {files.map((file, index) => (
                <div key={index} className="file-name">
                  {file.name}
                </div>
              ))}
            </div>
          </div>
        )}

        {error && <div className="error-message">{error}</div>}

        <button
          onClick={processAndDownload}
          disabled={processing || files.length === 0}
          className={`download-button ${
            processing || files.length === 0 ? "disabled" : ""
          }`}
        >
          {processing ? "Processing..." : "Download"}
        </button>
      </div>
    </div>
  );
};

export default ImageProcessor;